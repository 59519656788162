@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts';

body {
  letter-spacing: 0.03rem;
  font-family: 'Poppins', serif;
  
  @apply
  h-screen max-h-screen w-screen text-tundora;

  #root {
    @apply h-full w-full;
  }

  input, select, button, textarea, optgroup {
    letter-spacing: inherit;
  }
}

*::-webkit-scrollbar {
  @apply w-[0.3rem] h-[0.3rem] p-0;
}

*::-webkit-scrollbar-thumb {
  @apply
  bg-primary bg-opacity-80 rounded-[0.1rem] hover:bg-opacity-50;
}
