@font-face {
  font-family: 'Poppins';
  src: local('Poppins Thin'), local('Poppins-Thin'),
  url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins ThinItalic'), local('Poppins-ThinItalic'),
  url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
  url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins ExtraLightItalic'), local('Poppins-ExtraLightItalic'),
  url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Light'), local('Poppins-Light'),
  url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins LightItalic'), local('Poppins-LightItalic'),
  url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Italic'), local('Poppins-Italic'),
  url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins MediumItalic'), local('Poppins-MediumItalic'),
  url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins SemiBoldItalic'), local('Poppins-SemiBoldItalic'),
  url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins BoldItalic'), local('Poppins-BoldItalic'),
  url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
  url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins ExtraBoldItalic'), local('Poppins-ExtraBoldItalic'),
  url('../assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Black'), local('Poppins-Black'),
  url('../assets/fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins BlackItalic'), local('Poppins-BlackItalic'),
  url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
